import React from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlag } from "../contexts/FlagContext";




const Footer = () => {

  const location = useLocation();


  const path = location.pathname;
  let flagname = '';
  if (path.includes('/usa')) {
    flagname = 'USA';
  } else if (path.includes('/india')) {
    flagname = 'India';
  } else if (path.includes('/europe')) {
    flagname = 'Europe';
  } else {
    flagname = '';
  }

  const { t, i18n } = useTranslation();
  const { urlFlagName } = useFlag(); // Use the context



  return (
    <footer className={`${flagname === "Europe" ? "bg-white europeFooterTextColour" : flagname === "India" ? "bg-white indiaFooterTextColour" : "bg-gray-100 text-[#535454]"}`}>
      {/* <div className="container mx-auto py-8 px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div>
          <h4 className="font-bold mb-4">ABOUT US</h4>
          <ul>
            <li>
              <a href="#" className="link_text hover:underline">
                Our Story
              </a>
            </li>
            <li>
              <a href="#" className="link_text hover:underline">
                Shared Values
              </a>
            </li>
            <li>
              <a href="#" className="link_text hover:underline">
                Craft Stories
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="font-bold mb-4">CONTACT US</h4>
          <ul>
            <li>
              <a href="mailto:info@expobazaar.com" className="link_text underline">
                <i className="fas fa-envelope mr-2"></i>info@expobazaar.com
              </a>
            </li>
            <li>
              <a href="tel:+12563926035" className="link_text underline">
                <i className="fas fa-phone mr-2"></i>+1 (256) 392-6035
              </a>
            </li>
            <li>
              <a href="#" className="link_text hover:underline">
                Frequently Asked Questions
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="font-bold mb-4">REACH US</h4>
          <ul>
            <li>
              <a href="#" className="link_text hover:underline">
                Dallas Market Center
              </a>
            </li>
            <li>
              <a href="#" className="link_text hover:underline">
                Americas Mart
              </a>
            </li>
            <li>
              <a href="#" className="link_text hover:underline">
                MIX Apparel & Gift Mart
              </a>
            </li>
          </ul>
          <div>
            <select className="link_text w-full px-2 py-1 my-8 border border-gray-200 rounded">
              <option>United States</option>
              <option>Canada</option>
              <option>United Kingdom</option>
              <option>Australia</option>
            </select>
          </div>
        </div>
        <div>
          <h4 className="font-bold mb-4">WE ACCEPT</h4>
          <table className="table-auto w-full border-collapse">
            <tbody>
              <tr>
                <td className="link_text">
                  <a href="#">Payment Method 1</a>
                </td>
                <td className="link_text">
                  <a href="#">Payment Method 2</a>
                </td>
                <td className="link_text">
                  <a href="#">Payment Method 3</a>
                </td>
              </tr>
              <tr>
                <td className="link_text">
                  <a href="#">Payment Method 4</a>
                </td>
                <td className="link_text">
                  <a href="#">Payment Method 5</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="py-5">
            <h4 className="font-bold">FOLLOW US</h4>
            <div className="flex space-x-4">
              <a href="#" className="text-blue-600">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-blue-500">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#" className="text-blue-400">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-pink-600">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-red-600">
                <i className="fab fa-pinterest"></i>
              </a>
              <a href="#" className="text-red-600">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div className={`${flagname === "Europe" ? "bg-white border-[548091] text-center px-[5%] flex flex-col md:flex-row justify-center md:justify-between lg:justify-center gap-4 md:gap-0 align-middle footer-bottom border-t-[1px] py-6 " : flagname === "India" ? "bg-white border-[#8AA8B4] europe-footer-container footer-bottom border-t-[1px] md: py-6 " : "bg-[#f3f3f3] border-[#535454] footer-container footer-bottom border-t-[1px] md: py-6 "} `}>
        <div className=" footer-left-container">
          <p className=" font-medium">
            <span className="text-black"> {flagname === "Europe" ? t('footer.copyrightSign') : "©"}</span>{flagname === "Europe" ? t('footer.copyrightText') : flagname === "India" ? "2024 Expo Digital India Private Limited (ExpoBazaar) and its Affiliates. All rights reserved." : "2024 Expo Digital SCM B.V. (ExpoBazaar) and its Affiliates. All rights reserved."}
          </p>
        </div>
        <div className={`${flagname === "Europe" ? "flex flex-col lg:flex lg:flex-row" : "footer-right-container"}`}>
          <div>
            <a
              href={
                flagname === "Europe"
                  ? `https://eu.expobazaar.com${urlFlagName === "en" ? "" : `/${urlFlagName}`}/pages/our-policies`
                  : flagname === "India"
                    ? "https://expobazaarin.myshopify.com/pages/our-policy"
                    : "https://expobazaarus.myshopify.com/pages/our-policy"
              }
              className="font-medium hover:underline"
            >
              {flagname === "Europe" ? t('footer.links.ourPolicy') : "Our Policy"}
            </a>

            {" "}
          </div>

          <div>
            <a
              href={
                flagname === "Europe"
                  ? `https://eu.expobazaar.com${urlFlagName === "en" ? "" : `/${urlFlagName}`}/policies/terms-of-service`
                  : flagname === "India"
                    ? "https://expobazaarin.myshopify.com/policies/terms-of-service"
                    : "https://expobazaarus.myshopify.com/policies/terms-of-service"
              }
              className="font-medium lg:pl-5 hover:underline"
            >
              {flagname === "Europe" ? t('footer.links.termsOfUse') : "Terms of use"}
            </a>

            {" "}
          </div>
          <div>
            <a href="#" className=" font-medium lg:pl-5  hover:underline">
              {flagname === "Europe" ? t('footer.links.version') : flagname === "India" ? "" : "v:- 1.0"}
            </a>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
