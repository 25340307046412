export const CountryStateData = {
    "United States": {
        countryCode: "US",
        states: [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    Argentina: {
        countryCode: "AR",
        states: [
            "Buenos Aires",
            "Catamarca",
            "Chaco",
            "Chubut",
            "Córdoba",
            "Corrientes",
            "Entre Ríos",
            "Formosa",
            "Jujuy",
            "La Pampa",
            "La Rioja",
            "Mendoza",
            "Misiones",
            "Neuquén",
            "Río Negro",
            "Salta",
            "San Juan",
            "San Luis",
            "Santa Cruz",
            "Santa Fe",
            "Santiago del Estero",
            "Tierra del Fuego",
            "Tucumán",
        ],
    },
    Bolivia: {
        countryCode: "BO",
        states: [
            "Chuquisaca",
            "Cochabamba",
            "El Beni",
            "La Paz",
            "Oruro",
            "Pando",
            "Potosí",
            "Santa Cruz",
            "Tarija",
        ],
    },
    Brazil: {
        countryCode: "BR",
        states: [
            "Acre",
            "Alagoas",
            "Amapá",
            "Amazonas",
            "Bahia",
            "Ceará",
            "Espírito Santo",
            "Goiás",
            "Maranhão",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Pará",
            "Paraíba",
            "Paraná",
            "Pernambuco",
            "Piauí",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rondônia",
            "Roraima",
            "Santa Catarina",
            "São Paulo",
            "Sergipe",
            "Tocantins",
        ],
    },
    Chile: {
        countryCode: "CL",
        states: [
            "Arica y Parinacota",
            "Tarapacá",
            "Antofagasta",
            "Atacama",
            "Coquimbo",
            "Valparaíso",
            "Santiago Metropolitan",
            "O’Higgins",
            "Maule",
            "Ñuble",
            "Biobío",
            "Araucanía",
            "Los Ríos",
            "Los Lagos",
            "Aysén",
            "Magallanes",
        ],
    },
    Colombia: {
        countryCode: "CO",
        states: [
            "Amazonas",
            "Antioquia",
            "Arauca",
            "Atlántico",
            "Bogotá, D.C.",
            "Bolívar",
            "Boyacá",
            "Caldas",
            "Caquetá",
            "Casanare",
            "Cauca",
            "Cesar",
            "Chocó",
            "Córdoba",
            "Cundinamarca",
            "Guainía",
            "Guaviare",
            "Huila",
            "La Guajira",
            "Magdalena",
            "Meta",
            "Nariño",
            "Norte de Santander",
            "Putumayo",
            "Quindío",
            "Risaralda",
            "San Andrés y Providencia",
            "Santander",
            "Sucre",
            "Tolima",
            "Valle del Cauca",
            "Vaupés",
        ],
    },
    "Costa Rica": {
        countryCode: "CR",
        states: [
            "Alajuela",
            "Cartago",
            "Guanacaste",
            "Heredia",
            "Limón",
            "Puntarenas",
            "San José",
        ],
    },
    Cuba: {
        countryCode: "CU",
        states: [
            "Artemisa",
            "Camagüey",
            "Ciego de Ávila",
            "Cienfuegos",
            "Granma",
            "Guantánamo",
            "Holguín",
            "Isla de la Juventud",
            "La Habana",
            "Las Tunas",
            "Matanzas",
            "Mayabeque",
            "Pinar del Río",
            "Sancti Spíritus",
            "Santiago de Cuba",
            "Villa Clara",
        ],
    },
    "Dominican Republic": {
        countryCode: "DO",
        states: [
            "Azua",
            "Bahoruco",
            "Barahona",
            "Dajabón",
            "Distrito Nacional",
            "Duarte",
            "El Seibo",
            "Elías Piña",
            "Espaillat",
            "Hato Mayor",
            "Hermanas Mirabal",
            "Independencia",
            "La Altagracia",
            "La Romana",
            "La Vega",
            "María Trinidad Sánchez",
            "Monseñor Nouel",
            "Monte Cristi",
            "Monte Plata",
            "Pedernales",
        ],
    },
    Ecuador: {
        countryCode: "EC",
        states: [
            "Azuay",
            "Bolívar",
            "Cañar",
            "Carchi",
            "Chimborazo",
            "Cotopaxi",
            "El Oro",
            "Esmeraldas",
            "Galápagos",
            "Guayas",
            "Imbabura",
            "Loja",
            "Los Ríos",
            "Manabí",
            "Morona Santiago",
            "Napo",
            "Orellana",
            "Pastaza",
            "Pichincha",
            "Santa Elena",
            "Santo Domingo de los Tsáchilas",
            "Sucumbíos",
            "Tungurahua",
            "Zamora Chinchipe",
        ],
    },
    "Falkland Islands": {
        countryCode: "FK",
        states: [],
    },
    "French Guiana": {
        countryCode: "GF",
        states: [
            "Apatou",
            "Awala-Yalimapo",
            "Camopi",
            "Cayenne",
            "Grand-Santi",
            "Iracoubo",
            "Kourou",
            "Macouria",
            "Mana",
            "Maripasoula",
            "Matoury",
            "Montsinéry-Tonnegrande",
            "Ouanary",
            "Régina",
            "Rémire-Montjoly",
            "Saint-Élie",
            "Saint-Georges",
            "Saint-Laurent-du-Maroni",
            "Saül",
            "Sinnamary",
        ],
    },
    "El Salvador": {
        countryCode: "SV",
        states: [
            "Ahuachapán",
            "Cabañas",
            "Chalatenango",
            "Cuscatlán",
            "La Libertad",
            "La Paz",
            "La Unión",
            "Morazán",
            "San Miguel",
            "San Salvador",
            "San Vicente",
            "Santa Ana",
            "Sonsonate",
            "Usulután",
        ],
    },
    Guatemala: {
        countryCode: "GT",
        states: [
            "Alta Verapaz",
            "Baja Verapaz",
            "Chimaltenango",
            "Chiquimula",
            "El Progreso",
            "Escuintla",
            "Guatemala",
            "Huehuetenango",
            "Izabal",
            "Jalapa",
            "Jutiapa",
            "Petén",
            "Quetzaltenango",
            "Quiché",
            "Retalhuleu",
            "Sacatepéquez",
            "San Marcos",
            "Santa Rosa",
            "Sololá",
            "Suchitepéquez",
            "Totonicapán",
            "Zacapa",
        ],
    },
    Honduras: {
        countryCode: "HN",
        states: [
            "Atlántida",
            "Choluteca",
            "Colón",
            "Comayagua",
            "Copán",
            "Cortés",
            "El Paraíso",
            "Francisco Morazán",
            "Gracias a Dios",
            "Intibucá",
            "Islas de la Bahía",
            "La Paz",
            "Lempira",
            "Ocotepeque",
            "Olancho",
            "Santa Bárbara",
            "Valle",
            "Yoro",
        ],
    },
    Haiti: {
        countryCode: "HT",
        states: [
            "Artibonite",
            "Centre",
            "Grand'Anse",
            "Nippes",
            "Nord (North)",
            "Nord-Est (Northeast)",
            "Nord-Ouest (Northwest)",
            "Ouest (West)",
            "Sud (South)",
            "Sud-Est (Southeast)",
        ],
    },
    Mexico: {
        countryCode: "MX",
        states: [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila",
            "Colima",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "México",
            "Michoacán",
            "Morelos",
            "Nayarit",
            "Nuevo León",
            "Oaxaca",
            "Puebla",
            "Querétaro",
            "Quintana Roo",
            "San Luis Potosí",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucatán",
            "Zacatecas"
        ],
    },
    Nicaragua: {
        countryCode: "NI",
        states: [
            "Boaco",
            "Carazo",
            "Chinandega",
            "Chontales",
            "Estelí",
            "Granada",
            "Jinotega",
            "León",
            "Madriz",
            "Managua",
            "Masaya",
            "Matagalpa",
            "Nueva Segovia",
            "Río San Juan",
            "Rivas",
            "North Caribbean Coast",
            "South Caribbean Coast"
        ],
    },
    Panama: {
        countryCode: "PA",
        states: [
            "Bocas del Toro",
            "Chiriquí",
            "Coclé",
            "Colón",
            "Darién",
            "Herrera",
            "Los Santos",
            "Panamá",
            "Panamá Oeste",
            "Veraguas",
            "Emberá-Wounaan Region",
            "Guna Yala",
            "Ngäbe-Buglé Region"
        ],
    },
    Paraguay: {
        countryCode: "PY",
        states: [
            "Alto Paraguay",
            "Alto Paraná",
            "Amambay",
            "Boquerón",
            "Caaguazú",
            "Caazapá",
            "Canindeyú",
            "Central",
            "Concepción",
            "Cordillera",
            "Guairá",
            "Itapúa",
            "Misiones",
            "Ñeembucú",
            "Paraguarí",
            "Presidente Hayes",
            "San Pedro"
        ],
    },
    Peru: {
        countryCode: "PE",
        states: [
            "Amazonas",
            "Áncash",
            "Apurímac",
            "Arequipa",
            "Ayacucho",
            "Cajamarca",
            "Callao",
            "Cusco",
            "Huancavelica",
            "Huánuco",
            "Ica",
            "Junín",
            "La Libertad",
            "Lambayeque",
            "Lima",
            "Loreto",
            "Madre de Dios",
            "Moquegua",
            "Pasco",
            "Piura",
            "Puno",
            "San Martín",
            "Tacna",
            "Tumbes",
            "Ucayali"
        ],
    },
    Suriname: {
        countryCode: "SR",
        states: [
            "Brokopondo",
            "Commewijne",
            "Coronie",
            "Marowijne",
            "Nickerie",
            "Para",
            "Paramaribo",
            "Saramacca",
            "Sipaliwini",
            "Wanica"
        ],
    },
    Uruguay: {
        countryCode: "UY",
        states: [
            "Artigas",
            "Canelones",
            "Cerro Largo",
            "Colonia",
            "Durazno",
            "Flores",
            "Florida",
            "Lavalleja",
            "Maldonado",
            "Montevideo",
            "Paysandú",
            "Río Negro",
            "Rivera",
            "Rocha",
            "Salto",
            "San José",
            "Soriano",
            "Tacuarembó",
            "Treinta y Tres"
        ],
    },
    Venezuela: {
        countryCode: "VE",
        states: [
            "Amazonas",
            "Anzoátegui",
            "Apure",
            "Aragua",
            "Barinas",
            "Bolívar",
            "Carabobo",
            "Cojedes",
            "Delta Amacuro",
            "Falcón",
            "Guárico",
            "La Guaira",
            "Lara",
            "Mérida",
            "Miranda",
            "Monagas",
            "Nueva Esparta",
            "Portuguesa",
            "Sucre",
            "Táchira",
            "Trujillo",
            "Yaracuy",
            "Zulia"
        ],
    },
};
