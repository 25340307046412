import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { parsePhoneNumber } from 'awesome-phonenumber';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import * as EmailValidator from 'email-validator';
import { expoUrl } from "../config/config";
import uploadIcon from '../assets/uploadIcon.png';
import infoIcon from '../assets/infoIcon.png';
import { useTranslation } from 'react-i18next';
import { useFlag } from '../contexts/FlagContext';
import { CiCircleCheck } from "react-icons/ci";
import { GoInfo } from "react-icons/go";
import europeLogo from '../assets/expoLogoEurope.png';
import { Tooltip } from 'react-tooltip';
const postalCodes = require('postal-codes-js');



function EuropeRegistrationForm() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const countryPhoneCodes = {
        AT: "+43",   // Austria
        BE: "+32",   // Belgium
        NL: "+31",   // Netherlands
        BG: "+359",  // Bulgaria
        CY: "+357",  // Cyprus
        EE: "+372",  // Estonia
        FI: "+358",  // Finland
        IS: "+354",  // Iceland
        IE: "+353",  // Ireland
        LV: "+371",  // Latvia
        LT: "+370",  // Lithuania
        MT: "+356",  // Malta
        NO: "+47",   // Norway
        SE: "+46",   // Sweden
        TR: "+90",   // Turkey
        GB: "+44",   // United Kingdom
        HR: "+385",  // Croatia
        CZ: "+420",  // Czech Republic
        DK: "+45",   // Denmark
        DE: "+49",   // Germany
        HU: "+36",   // Hungary
        PL: "+48",   // Poland
        SK: "+421",  // Slovakia
        CH: "+41",   // Switzerland
        FR: "+33",   // France
        GR: "+30",   // Greece
        LU: "+352",  // Luxembourg
        RO: "+40",   // Romania
        IT: "+39",   // Italy
        SI: "+386",  // Slovenia
        PT: "+351",  // Portugal
        ES: "+34",   // Spain
    };


    const [formData, setFormData] = useState({
        ownerName: "",
        business: "",
        businessName: "",
        addressline1: "",
        addressline2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        prefix: "",
        firstName: "",
        lastName: "",
        primaryEmail: "",
        language: "dutch",
        primaryPhone: "",
        telephone: "",
        vatNumber: "",
        cocNumber: "",
        cocDocument: "",
    });

    console.log("formData", formData)

    const countryCodes = {
        Austria: "AT",
        Belgium: 'BE',
        Netherlands: 'NL',
        Bulgaria: 'BG',
        Cyprus: 'CY',
        Estonia: 'EE',
        Finland: 'FI',
        Iceland: 'IS',
        Ireland: 'IE',
        Latvia: 'LV',
        Lithuania: 'LT',
        Malta: 'MT',
        Norway: 'NO',
        Sweden: 'SE',
        Turkey: 'TR',
        'United Kingdom': 'GB',
        Croatia: 'HR',
        'Czech Republic': 'CZ',
        Denmark: 'DK',
        Germany: 'DE',
        Hungary: 'HU',
        Poland: 'PL',
        Slovakia: 'SK',
        Switzerland: 'CH',
        France: 'FR',
        Greece: 'GR',
        Luxembourg: 'LU',
        Romania: 'RO',
        Italy: 'IT',
        Slovenia: 'SI',
        Portugal: 'PT',
        Spain: 'ES'
    };

    // Define regex patterns for European countries
    const postalCodePatterns = {
        AT: /^[0-9]{4}$/, // Austria
        BE: /^[0-9]{4}$/, // Belgium
        NL: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/, // Netherlands
        BG: /^[0-9]{4}$/, // Bulgaria
        CY: /^[0-9]{4}$/, // Cyprus
        EE: /^[0-9]{5}$/, // Estonia
        FI: /^[0-9]{5}$/, // Finland
        IS: /^[1-9][0-9]{2}$/, // Iceland
        IE: /^[A-Z0-9]{2}\s?[0-9][A-Z]{2}$/i, // Ireland
        LV: /^[0-9]{4}$/, // Latvia
        LT: /^[0-9]{5}$/, // Lithuania
        MT: /^[A-Z]{3}\s?[0-9]{4}$/, // Malta
        NO: /^[0-9]{4}$/, // Norway
        SE: /^[0-9]{3}\s?[0-9]{2}$/, // Sweden
        TR: /^[0-9]{5}$/, // Turkey
        GB: /^[A-Z]{1,2}[0-9R][0-9A-Z]? \d[A-Z]{2}$/i, // United Kingdom
        HR: /^[0-9]{5}$/, // Croatia
        CZ: /^[0-9]{5}$/, // Czech Republic
        DK: /^[0-9]{4}$/, // Denmark
        DE: /^[0-9]{5}$/, // Germany
        HU: /^[0-9]{4}$/, // Hungary
        PL: /^[0-9]{2}-[0-9]{3}$/, // Poland
        SK: /^[0-9]{5}$/, // Slovakia
        CH: /^[0-9]{4}$/, // Switzerland
        FR: /^[0-9]{5}$/, // France
        GR: /^[0-9]{5}$/, // Greece
        LU: /^[LUX]\d{4}$/, // Luxembourg
        RO: /^[0-9]{6}$/, // Romania
        IT: /^[0-9]{5}$/, // Italy
        SI: /^[0-9]{4}$/, // Slovenia
        PT: /^[0-9]{4}-[0-9]{3}$/, // Portugal
        ES: /^[0-9]{5}$/, // Spain
    };



    const languageGroups = {
        dutch: ['Belgium', 'Netherlands'],
        english: ['Bulgaria', 'Cyprus', 'Estonia', 'Finland', 'Iceland', 'Ireland', 'Latvia', 'Lithuania', 'Malta', 'Norway', 'Sweden', 'Turkey', 'United Kingdom'],
        german: ['Austria', 'Croatia', 'Czech Republic', 'Denmark', 'Germany', 'Hungary', 'Poland', 'Slovakia', 'Switzerland'],
        french: ['France', 'Greece', 'Luxembourg', 'Romania'],
        italian: ['Italy', 'Slovenia'],
        spanish: ['Portugal', 'Spain']
    };

    const countryTooltipMap = {
        AT: "Austrian Federal Economic Chamber (WKO)",
        BE: "Federation of Belgian Chambers of Commerce (FEB/VBO)",
        BG: "Bulgarian Chamber of Commerce and Industry (BCCI)",
        HR: "Croatian Chamber of Economy (HGK)",
        CY: "Cyprus Chamber of Commerce and Industry (KEVE)",
        CZ: "Czech Chamber of Commerce (HK ČR)",
        DK: "Danish Chamber of Commerce (Dansk Erhverv)",
        EE: "Estonian Chamber of Commerce and Industry (ECCI)",
        FI: "Finland Chamber of Commerce",
        FR: "Mouvement des Entreprises de France (MEDEF)",
        DE: "Association of German Chambers of Industry and Commerce (DIHK)",
        GR: "Hellenic Chamber of Hotels",
        HU: "Hungarian Chamber of Commerce and Industry (MKIK)",
        IS: "Icelandic Chamber of Commerce",
        IE: "Chambers Ireland",
        IT: "Italian Chamber of Commerce (Unioncamere)",
        LV: "Latvian Chamber of Commerce and Industry (LTRK)",
        LT: "Association of Lithuanian Chambers of Commerce, Industry and Crafts (ALCCIC)",
        LU: "Chamber of Commerce of the Grand Duchy of Luxembourg",
        MT: "The Malta Chamber of Commerce, Enterprise and Industry",
        NL: "Netherlands Chamber of Commerce (KVK)",
        NO: "The Confederation of Norwegian Enterprise (NHO)",
        PL: "Polish Chamber of Commerce",
        PT: "Portuguese Chamber of Commerce and Industry (CCIP)",
        RO: "Romanian Chamber of Commerce and Industry (CCIR)",
        SK: "Slovak Chamber of Commerce and Industry (SOPK)",
        SI: "Chamber of Commerce and Industry of Slovenia (CCIS)",
        ES: "Spanish Chamber of Commerce (Cámara de Comercio de España)",
        SE: "Swedish Chamber of Commerce",
        CH: "Swiss Federation of Commerce and Industry (economiesuisse)",
        TR: "Union of Chambers and Commodity Exchanges of Turkey (TOBB)",
        GB: "British Chambers of Commerce (BCC)"
    };




    const [searchCompanyLoading, setSearchCompanyLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isSoleProprietorship, setIsSoleProprietorship] = useState(false)
    const [companyFound, setCompanyFound] = useState(null);
    const [companyFoundMessage, setCompanyFoundMessage] = useState("");
    const [vatMessage, setVatMessage] = useState("");
    const [showThankyouPopup, setShowThankyouPopup] = useState(false);
    const [ipAddress, setIpAddress] = useState("");
    const [vatValid, setVatValid] = useState(null);
    const [pdfUploaded, setPdfUploaded] = useState(null);
    const [pdfLoader, setPdfLoader] = useState(false);
    const [vatNumberLoader, setVatNumberLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [pdfLink, setPdfLink] = useState("");
    const { urlFlagName } = useFlag();

    console.log("PDF LINK", pdfLink)
    console.log(vatValid);
    console.log("Company found ====>", companyFound);
    console.log("Company found message====>", companyFoundMessage);

    console.log("Public IP address ==>>", ipAddress);
    const goToHomePage = () => {
        window.location.href = `https://eu.expobazaar.com/account`;
    };

    const handleCrossClicked = (e) => {
        setShowThankyouPopup(false);
        setButtonDisable(false);
        window.location.reload(); // This will refresh the page
    };


    const getIpAddresses = () => {
        axios.get('https://api.ipify.org?format=json')
            .then(response => {
                console.log("Public IP Address:", response.data.ip);
                setIpAddress(response.data.ip);

            })
            .catch(error => {
                console.error("Error fetching IP address:", error);
            });
    };

    console.log("Company found state ", companyFound);
    const { selectedFlag } = useFlag();

    useEffect(() => {
        i18n.changeLanguage(selectedFlag);
    }, [selectedFlag]);

    const flagname = selectedFlag;
    console.log("FLAGNAME IS ==================================>>>>>>>>>>>>>>>>>>>>>>", flagname)

    useEffect(() => {
        const storedFormData = JSON.parse(localStorage.getItem("formData"));
        if (storedFormData) {
            setFormData(storedFormData);
        }
    }, []);
    useEffect(() => {
        getIpAddresses();
    }, []);
    useEffect(() => {
        if (errorMessage !== "") {
            setSubmitLoader(false);
        }
    }, [errorMessage]);
    useEffect(() => {
        if (formData.business === "sole-proprietorship") {
            setIsSoleProprietorship(true);
        }
    });

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const requestData = {
        company: {
            name: formData.businessName,
        },
        companyLocation: {
            name: formData.city,
            billingAddress: {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address1: formData.addressline1,
                address2: formData.addressline2,
                city: formData.city,
                zip: formData.postalCode,
                countryCode: formData.country,
            },
        },
        taxId: formData.vatNumber,
        companyContact: {
            email: formData.primaryEmail,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.primaryPhone,
        },
        vat_numbers: formData.vatNumber,
        coc_number: formData.cocNumber,
        coc_document: pdfLink,
        business_type: formData.business,
        business: formData.businessName,
        ipAddress: ipAddress,
        language: formData.language,
    };

    console.log(requestData);



    // const validateVatNumber = async (e) => {
    //     setVatNumberLoader(true);
    //     e.preventDefault();
    //     try {
    //         const response = await fetch('https://services.expobazaar.com/api/vat/validation', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ vat_number: formData.vatNumber }),
    //         });

    //         if (response.ok) {
    //             const result = await response.json();
    //             setVatValid(result.data.data.valid);
    //             setVatNumberLoader(false);

    //             if (result?.data?.data?.valid === true ) {
    //                 console.log("Result is :::::::::::===>>>>>",result);
    //                 searchCompanyAPI(formData.vatNumber);
    //             } else {
    //                 setErrorMessage("Vat Number is Invalid, Please fill valid Vat Number");
    //                 console.log(errorMessage);
    //             }
    //         } else {
    //             console.error('Failed to validate VAT number');
    //             setVatValid(false);
    //             setVatNumberLoader(false);

    //         }
    //     } catch (error) {
    //         console.error('Error occurred during VAT validation:', error);
    //         setVatValid(false);
    //         setVatNumberLoader(false);

    //     }
    // };

    const validateVatNumber = async (e) => {
        e.preventDefault();
        setVatNumberLoader(true);

        try {
            const response = await fetch('https://services.expobazaar.com/api/vat/validate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ vat_number: formData.vatNumber }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Result is :::::::::::===>>>>>", result)
                setVatValid(result?.data?.data?.valid);
                setVatNumberLoader(false);

                if (result?.data?.data?.valid) {
                    // console.log("Result is :::::::::::===>>>>>", result);
                    searchCompanyAPI(formData.vatNumber);
                } else {
                    const message = t('errors.vatMessage');
                    setVatValid(false);
                    // setErrorMessage(message);
                    setVatMessage(message);
                    console.log(message);
                }
            } else {
                console.error('Failed to validate VAT number');
                setVatValid(false);
                setVatNumberLoader(false);
            }
        } catch (error) {
            console.error('Error occurred during VAT validation:', error);
            setVatValid(false);
            setVatNumberLoader(false);
        }
    };


    const uploadCocDocument = async (file) => {
        setPdfLoader(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${expoUrl}/api/awsmedia/cocfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                setPdfUploaded(true);
                setPdfLoader(false);
                setPdfLink(response?.data?.fileUrl);
            } else {
                console.log("Unexpected response status:", response.status);
                setPdfUploaded(false);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const createAccountAPI = async (e) => {
        setSubmitLoader(true);
        console.log(requestData);
        try {
            const response = await axios.post(
                `${expoUrl}/api/europe/companies/create`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                console.log("Success", response);
                setSubmitLoader(false);
                setShowThankyouPopup(true);
                // if(response.success === false ){
                //     console.log(response.message);
                // }
            } else {
                console.log("Unexpected response status:", response.status);
                // console.log("Phone number error message" ,response?.data?.Error[0]?.message)
                setErrorMessage(response?.data?.Error[0]?.message);
                setButtonDisable(false);

            }
        } catch (error) {
            if (error.response) {
                console.error("Error Response:", error.response.data);
                console.log("Error Response:", error?.response?.data);
                setErrorMessage(error?.response?.data?.Error);
                console.error("Status:", error.response.status);
                console.log("Status:", error?.response?.status);
                setButtonDisable(false);

            } else if (error.request) {
                // Request was made but no response received
                console.error("Error Request:", error.request);
                console.log("Error Request:", error?.request);
                setButtonDisable(false);

            } else {
                // Something happened in setting up the request
                console.error("Error Message:", error.message);
                console.log("Error Message:", error);
                setButtonDisable(false);

            }
        }
    }

    const handleChange = (e) => {
        const { id, value } = e.target;

        // Update formData based on id and value
        let newValue = value;

        if (id === "business") {
            setIsSoleProprietorship(value === "sole-proprietorship");
        // } else if (id === "telephone") {
        //     newValue = value.replace(/\D/g, "").slice(0, 10);
        } else if (id === "primaryPhone" || id === "telephone") {
            newValue = value.replace(/[^0-9+]/g, "").slice(0, 13);

        }
        // else if (id === "postalCode") {
        //     newValue = value.replace(/\D/g, "").slice(0, 5);
        // }


        // if (id === 'country') {
        //     function getLanguageByCountry(country) {
        //         for (const [language, countries] of Object.entries(languageGroups)) {
        //             if (countries.includes(country)) {
        //                 return language;
        //             }
        //         }
        //         return '';
        //     }
        //     const language = getLanguageByCountry(value);
        //     setFormData({ ...formData, [id]: value, language });
        // } else {
        //     setFormData({ ...formData, [id]: newValue });
        // }

        if (id === 'country') {
            const countryCode = countryCodes[value];
            function getLanguageByCountry(country) {
                for (const [language, countries] of Object.entries(languageGroups)) {
                    if (countries.includes(country)) {
                        return language;
                    }
                }
                return '';
            }
            const language = getLanguageByCountry(value);
            setFormData({ ...formData, [id]: countryCode, language });
            console.log("Country code is ============>.>>>>....>>>", formData.country);
        } else {
            setFormData({ ...formData, [id]: newValue });
        }

        // if ((id === 'postalCode' || id === 'country') && (formData.country !== "")) {
        //     const countryCode = formData.country;
        //     const postalValid = postcodeValidator(formData.postalCode, countryCode);
        //     console.log("POSTAL CODE IS VALID ?", postalValid);
        //     if (postalValid === false) {
        //         setErrorMessage(t('errors.postalMessage'));
        //         setSubmitLoader(false);  // Ensure the loader is stopped
        //         return;
        //     }
        // }

        // if ((id === 'postalCode' || id === 'country') && (formData.country !== "")) {
        //     const countryCode = formData.country; // Get the country code
        //     if (countryCode) {
        //         const pattern = postalCodePatterns[countryCode];
        //         if (pattern) {
        //             const postalValid = pattern.test(formData.postalCode);
        //             console.log("POSTAL CODE IS VALID ?", postalValid);
        //             if (!postalValid) {
        //                 setErrorMessage(t('errors.postalMessage'));
        //                 setSubmitLoader(false);  // Ensure the loader is stopped
        //                 return;
        //             }
        //         } else {
        //             setErrorMessage("Postal code pattern not defined for this country.");
        //             setSubmitLoader(false);  // Ensure the loader is stopped
        //             return;
        //         }
        //     } else {
        //         setErrorMessage("Invalid country selected.");
        //         setSubmitLoader(false);  // Ensure the loader is stopped
        //         return;
        //     }
        // }


        if (id === 'vatNumber') {
            setVatValid(null);
            setCompanyFound(null);
            setVatMessage("");
        }

        if (id === 'cocDocument') {
            uploadCocDocument(e.target.files[0]);

        }

        setErrorMessage("");
        setButtonDisable(false);
    };
    useEffect(() => {
        const selectedCountryPhoneCode = countryPhoneCodes[formData.country] || "";
        console.log("selectedCountryPhoneCode", selectedCountryPhoneCode);

        setFormData((prevFormData) => ({
            ...prevFormData,
            primaryPhone: selectedCountryPhoneCode,
            telephone: selectedCountryPhoneCode,
        }));
    }, [formData.country]);

    const searchCompanyAPI = async (num) => {
        setSearchCompanyLoading(true);
        try {
            const response = await axios.get(`${expoUrl}/api/europe/companies/companysearch/${num}`);
            console.log("searchCompanyresponse =>>>", response)
            setCompanyFound(response.data.success);
            // setCompanyFoundMessage(response.data.message)
            setCompanyFoundMessage(t('errors.companyFoundMessage'));
        } catch (error) {
            console.error(error);
            setCompanyFound(false);

        } finally {
            setSearchCompanyLoading(false);
        }
    };

    const sendDataToSearchAPI = async () => {
        try {
            const response = await axios.post(
                `${expoUrl}/api/europe/companies/search`,
                {
                    email: formData.primaryEmail,
                    phone: formData.primaryPhone,
                }
            );
            console.log("Search API Response:", response?.data?.success);
            console.log("Company found ======================>>>>>>>>>>>>>", companyFound)

            if (response?.data?.success === true) {
                createAccountAPI();
            } else {
                // Handle cases where companyFound is false or response success is not true
                console.log("Email");
                console.log("Response of search API for email phone number", response)
                setErrorMessage(response?.data?.message);
                setButtonDisable(false);

            }
        } catch (error) {
            console.error("Error sending data to search API:", error);
            setErrorMessage('Failed to connect to the API');
            setButtonDisable(false);

        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true);
        setButtonDisable(true);


        // Validate email
        const validMail = EmailValidator.validate(formData.primaryEmail);
        if (!validMail) {
            setErrorMessage("Invalid email address, Please fill valid email");
            setSubmitLoader(false);
            return;
        }

        // Perform necessary data transformations
        if (formData.business === "sole-proprietorship" && formData.businessName === "") {
            formData.businessName = formData.ownerName;
            formData.ownerName = "";
        }

        // if(formData.country!== ""){
        //     const countryCode = formData.country;
        //     const postalValid = postcodeValidator(formData.postalCode, countryCode);
        //     console.log("POSTAL CODE IS VALID ?", postalValid);
        //     if (postalValid === false) {
        //         setErrorMessage(t('errors.postalMessage'));
        //         setSubmitLoader(false);  // Ensure the loader is stopped
        //         return;
        //     }
        // }



        if (formData.country) {
            const countryCode = formData.country; // Get the country code
            if (countryCode) {
                const pattern = postalCodePatterns[countryCode];
                if (pattern) {
                    const postalValid = pattern.test(formData.postalCode);
                    console.log("POSTAL CODE IS VALID ?", postalValid);
                    if (!postalValid) {
                        setErrorMessage(t('errors.postalMessage'));
                        setSubmitLoader(false);  // Ensure the loader is stopped
                        return;
                    }
                } else {
                    setErrorMessage("Postal code pattern not defined for this country.");
                    setSubmitLoader(false);  // Ensure the loader is stopped
                    return;
                }
            } else {
                setErrorMessage("Invalid country selected.");
                setSubmitLoader(false);  // Ensure the loader is stopped
                return;
            }
        }

        if (companyFound === false) {
            await sendDataToSearchAPI();
        } else {
            setButtonDisable(false);
            return;
        }
        setSubmitLoader(false);
    };





    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isRedirect = queryParams.get('redirect') === 'true';

    return (
        <div className="europe-container mx-auto px-4 py-6">
            <h1 className="text-center text-2xl  mt-4 mb-8">{t('section.heading')}</h1>
            {isRedirect && (
                <section className="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">

                    <p className="text-red-500">{t('errors.redirectMessage.text1')}<br></br> {t('errors.redirectMessage.text2')} <span className="font-bold text-red-500">{t('errors.redirectMessage.supportLink')}</span> {t('errors.redirectMessage.text3')}</p>
                </section>
            )}

            <section className="border p-4 md:p-8 mb-6 rounded-lg businessInfo">
                <p className="mb-4">
                    {t('section.paragraph1')}
                </p>
                <p className="mb-4">
                    {t('section.paragraph2')}
                </p>
                <p className="mb-0">
                    {t('section.paragraph3')}
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <b>{t('section.list.item1')}</b>
                    </li>
                    <li>
                        <b>{t('section.list.item2')}</b>
                    </li>
                </ul>
                <p className="mb-4">
                    {t('section.paragraph4')}{" "}
                    {/* <a href={`https://eu.expobazaar.com/${urlFlagName}/pages/faq`} className="underline">
                        <b>{t('section.faqLink')}</b>
                    </a> */}
                    <a
                        href={`https://eu.expobazaar.com${urlFlagName === "en" ? "" : `/${urlFlagName}`}/pages/faq`}
                        className="underline"
                    >
                        <b>{t('section.faqLink')}</b>
                    </a>

                    {" "}
                    {t('section.paragraph5')}{" "}
                    <a href="mailto:info@expobazaar.com" className="underline">
                        <b>{t('section.emailLink')}</b>
                    </a>
                    . {t('section.paragraph6')}
                </p>
                {/* <p className="">
                    <b>{t('section.important')}</b>
                </p> */}
            </section>

            <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="space-y-6">
                    <section className="pb-6">
                        {/* <h2 className="text-xl font-semibold mb-4">Company Details</h2> */}
                        <h2 className="text-xl font-semibold mb-4">{t('form.companyDetails')}</h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">


                            <div className={`flex flex-col ${formData.business === "sole-proprietorship" ? 'mt-4' : 'mt-0'}`}>
                                <div className="flex flex-col md:flex-row items-center">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="businessName"
                                    >
                                        {t('form.businessName')}*</label>

                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                        id="businessName"
                                        type="text"
                                        placeholder={t('form.businessNamePlaceholder')} value={formData.businessName}
                                        onChange={handleChange}
                                        required
                                    // required={isSoleProprietorship === true}
                                    />
                                </div>
                                <div className="text-[10px] opacity-[50%] font-light pt-2 w-[65%] block md:ml-[35%]">
                                    {isSoleProprietorship === true ? (<p>{t('form.ownerNameNote')}</p>
                                    ) : (null)}
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="business"
                                >
                                    {/* Business Type* */}
                                    {t('form.business')}*
                                </label>
                                <select
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                    id="business"
                                    value={formData.business}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled hidden>
                                        {t('form.businessPlaceholder')}
                                    </option>
                                    <option value="sole-proprietorship">Sole proprietorship</option>
                                    <option value="Charitable-incorporated-Organization">Charitable incorporated Organization</option>
                                    <option value="Corporation">Corporation</option>
                                    <option value="General-partnership">General partnership</option>
                                    <option value="Limited-company">Limited company</option>
                                    <option value="Limited-liability">Limited liability</option>
                                    <option value="Limited-partnership">Limited partnership</option>
                                    <option value="Civil-law-partnership">Civil law partnership</option>
                                    <option value="Community-Association">Community Association</option>
                                    <option value="Partnership">Partnership</option>
                                    {/* <option value="Others">Others</option> */}
                                </select>
                            </div>

                            {formData.business === "sole-proprietorship" ? (
                                <div className="flex flex-col md:flex-row items-baseline">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="ownerName"
                                    >
                                        {/* Owner Name* */}
                                        {t('form.ownerName')}*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                        id="ownerName"
                                        type="text"
                                        // placeholder="Enter Owner Name"
                                        placeholder={t('form.ownerNamePlaceholder')}
                                        value={formData.ownerName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            ) : (
                                <div className="flex flex-col">
                                    {/* Your content for sole proprietorship can go here if needed */}
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="pb-6">
                        <h2 className="text-xl font-semibold mb-4">{t('form.billingAddress')}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
                            <div className="flex  flex-col md:flex-row  items-center ">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="addressline1"
                                >
                                    {t('form.addressline1')}*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs "
                                    id="addressline1"
                                    type="text"
                                    placeholder={t('form.addressline1Placeholder')}
                                    value={formData.addressline1}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex  flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="addressline2"
                                >
                                    {t('form.addressline2')}
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                    id="addressline2"
                                    type="text"
                                    placeholder={t('form.addressline2Placeholder')} value={formData.addressline2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="postalCode"
                                >
                                    {t('form.postalCode')}*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                    id="postalCode"
                                    type="text"
                                    placeholder={t('form.postalCodePlaceholder')} value={formData.postalCode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="city"
                                >
                                    {t('form.city')}*
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                    id="city"
                                    type="text"
                                    placeholder={t('form.cityPlaceholder')} value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="state"
                                >
                                    <div className="">
                                        {t('form.state')}
                                    </div>
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                    id="state"
                                    type="text"
                                    placeholder={t('form.statePlaceholder')} value={formData.state}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <div className="flex items-center formLables">
                            <label
                                className="block text-sm font-medium mb-1 w-2/4 md:w-1/3"
                                htmlFor="country"
                            >
                                {t('form.country')}*
                            </label>

                            <select
                                className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                id="country"
                                value={formData.country}
                                onChange={handleChange}
                                required
                            >
                                <option value="">
                                    {t('form.countryPlaceholder')}
                                </option>

                                {Object.values(languageGroups).flat().map((country) => (
                                    <option key={country} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                            <div className="flex flex-col md:flex-row items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="country"
                                >
                                    {t('form.country')}*
                                </label>

                                <select
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                    id="country"
                                    value={Object.keys(countryCodes).find(key => countryCodes[key] === formData.country) || ''}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">
                                        {t('form.countryPlaceholder')}
                                    </option>

                                    {Object.keys(countryCodes).map((country) => (
                                        <option key={countryCodes[country]} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                    </section>

                    <section className="pb-6">
                        <h2 className="text-xl font-semibold mb-4">{t('form.primaryContact')}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
                            <div className="flex flex-col md:flex-row  items-center">

                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="firstName"
                                >
                                    {t('form.firstName')}*
                                </label>
                                <div className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs">
                                    <label htmlFor="prefix" className="block text-sm font-medium text-gray-700"></label>

                                    <select
                                        id="prefix"
                                        value={formData.prefix}
                                        onChange={handleChange}
                                        className="europe-prefix text-base border-gray-300 w-[17%] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        required
                                    >
                                        <option value="" disabled hidden>Sal.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="No-preference">No Preference</option>

                                    </select>
                                    <input
                                        className="focus:outline-none pl-2 w-[65%] md:w-[70%]"
                                        id="firstName"
                                        type="text"
                                        placeholder={t('form.firstNamePlaceholder')} value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="lastName"
                                >
                                    {t('form.lastName')}
                                </label>
                                <input
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2  text-xs"
                                    id="lastName"
                                    type="text"
                                    placeholder={t('form.lastNamePlaceholder')} value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col md:flex-row items-center ">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="primaryEmail"
                                    >
                                        {t('form.primaryEmail')}*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                        id="primaryEmail"
                                        type="email"
                                        placeholder={t('form.primaryEmailPlaceholder')} value={formData.primaryEmail}
                                        onChange={handleChange}
                                        required
                                    />
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                <label
                                    className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                    htmlFor="language"
                                >
                                    {t('form.language')}*<span className="text-xs">{t('form.languagePreference')}</span>
                                </label>
                                <select
                                    className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                    id="language"
                                    value={formData.language}
                                    onChange={handleChange}
                                // onChange={(e) => changeLanguage(e.target.value)}
                                // required

                                >
                                    <option value="" disabled hidden>
                                        Select Preferred Language
                                    </option>
                                    <option value="dutch">Dutch</option>
                                    <option value="german">German</option>
                                    <option value="french">French</option>
                                    <option value="italian">Italian</option>
                                    <option value="spanish">Spanish</option>
                                    <option value="english">English</option>
                                </select>
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="primaryPhone"
                                    >
                                        {t('form.primaryPhone')}*
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                        id="primaryPhone"
                                        type="text"
                                        placeholder={t('form.primaryPhonePlaceholder')} value={formData.primaryPhone}
                                        onChange={handleChange}
                                        required
                                    />
                            </div>
                            <div className="flex flex-col md:flex-row  items-center">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="telephone"
                                    >
                                        {t('form.telephone')}
                                    </label>
                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                        id="telephone"
                                        type="text"
                                        placeholder={t('form.telephonePlaceholder')} value={formData.telephone}
                                        onChange={handleChange}
                                    />
                            </div>
                        </div>
                    </section>

                    <section className="">
                        <h2 className="text-xl font-semibold mb-4">{t('form.taxInformation')}</h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border rounded-lg p-4 md:p-6">
                            <div className="flex flex-col md:flex-row items-center">
                                    <label
                                        className="block text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="vatNumber"
                                    >
                                        {t('form.vatNumber')}*
                                    </label>
                                    <div className="w-full md:w-2/3 flex">
                                        <input
                                            className=" w-full md:w-[70%]  border border-[#00425B] rounded px-3 py-2 text-xs"
                                            id="vatNumber"
                                            type="text"
                                            placeholder={t('form.vatNumberPlaceholder')}
                                            value={formData.vatNumber}
                                            onChange={handleChange}
                                            required
                                        />


                                        <button
                                            className={`${(vatValid === null) || (vatValid === true && companyFound === true)
                                                ? 'europe-valid-subBtn'
                                                : vatValid === false
                                                    ? 'bg-red-500 rounded-lg'
                                                    : vatValid === true && companyFound === false
                                                        // ? 'bg-green-500 rounded-lg'
                                                        ? 'bg-[#548091] rounded-lg'
                                                        : ''} 
                                             md:w-[27%] ml-2 text-xs md:text-sm text-white py-[7px] px-1 flex justify-center items-center`}

                                            onClick={e => validateVatNumber(e)}
                                            disabled={!formData.vatNumber || formData.vatNumber.length === 0}
                                        >
                                            {vatNumberLoader ? (
                                                <div role="status" className="flex justify-center items-center">
                                                    <svg
                                                        aria-hidden="true"
                                                        className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                        viewBox="0 0 100 101"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"
                                                        />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : vatValid === null ? (
                                                t('form.verify')
                                            ) : (
                                                <div className={`font-bold`}>
                                                    {vatValid === true && companyFound === false ? (
                                                        <CiCircleCheck size={20} />
                                                    ) : (vatValid === false) || ((vatValid === true && companyFound === true)) ? (
                                                        <GoInfo size={20} />
                                                    ) : null}
                                                </div>
                                            )}
                                        </button>



                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row items-center">

                                    <label
                                        className="flex text-sm font-medium mb-1 w-full md:w-1/3"
                                        htmlFor="cocNumber"
                                    >
                                        <div className="w-full md:w-[65%] break-words">
                                            {t('form.cocNumber')}{formData.country === 'NL' ? "*" : ""}
                                        </div>
                                        <div className="pt-[2px] px-1">
                                            <img src={infoIcon} className="" id="CoCNumberIcon" data-tooltip-id="CoCNumberTooltip" data-tooltip-content={countryTooltipMap[formData.country] || "No information available"}
                                            />
                                        </div>
                                    </label>
                                    <Tooltip id="CoCNumberTooltip" />

                                    <input
                                        className="w-full md:w-2/3 border border-[#00425B] rounded px-3 py-2 text-xs"
                                        id="cocNumber"
                                        type="text"
                                        // placeholder={t('form.cocNumberPlaceholder')}
                                        placeholder={countryTooltipMap[formData.country] ? countryTooltipMap[formData.country] : t('form.cocNumberPlaceholder')}
                                        value={formData.cocNumber}
                                        onChange={handleChange}
                                        required={formData.country === 'NL'} // Set required only if country is NL
                                    />

                            </div>
                            <div className="flex flex-col md:flex-row items-center">
                                    <label
                                        className="flex text-sm font-medium mb-1 w-full md:w-[35%]"
                                        htmlFor="cocDocument"
                                    >
                                        <div className="w-[70%] break-words">
                                            {t('form.cocDocument')} {formData.country === 'NL' ? "*" : ""}
                                        </div>
                                    </label>
                                    <div className="relative w-full md:w-2/3">
                                        <input
                                            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                            id="cocDocument"
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleChange}
                                            value={formData.cocDocument}
                                            required={formData.country === 'NL'} // Set required only if country is NL

                                        />
                                        <div className="flex border border-[#00425B] rounded px-3 py-2 items-center">
                                            <span className="flex-grow text-xs break-words w-[80%]">{formData.cocDocument ? formData.cocDocument : t('form.cocDocumentPlaceholder')}</span>
                                            <label htmlFor="cocDocument" className="cursor-pointer">
                                                {
                                                    pdfLoader === true ? (
                                                        <div role="status">
                                                            <svg
                                                                aria-hidden="true"
                                                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                viewBox="0 0 100 101"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor"
                                                                />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill"
                                                                />
                                                            </svg>
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    ) : pdfUploaded === true && pdfLoader === false ? (
                                                        <CiCircleCheck size={20} color="green" />
                                                    ) : (
                                                        <img src={uploadIcon} alt="Upload" className="ml-2" />
                                                    )
                                                }

                                            </label>
                                        </div>
                                    </div>
                            </div>
                            <div className="flex items-center flex-col">
                                <div className="flex items-center flex-row w-[100%]">
                                    <p className="text-[10px]">{t('form.note')}</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {vatMessage && <section class="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">
                        <p className=" text-xs text-red-500">{vatMessage}</p>
                    </section>
                    }
                    {companyFound && <section class="border p-4 md:p- 8 mb-6 rounded-lg bg-red-100 border-red-700 text-center">
                        <p className=" text-xs text-red-500">{companyFoundMessage}</p>
                    </section>
                    }



                    {/* <div className="europe-container mx-auto px-4 py-6 relative">
                        {showThankyouPopup && (
                            <>
                                <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-96 pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center">
                                    <button className="text-3xl md:text-4xl absolute right-4 top-4" onClick={e => handleCrossClicked(e)}>&times;</button>
                                    <img src={europeLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-32 md:w-40" />
                                    <div className='lastPageContainer pt-4 pb-4 md:pt-5 md:pb-5'>
                                        <h1 className="text-xl md:text-2xl font-semibold mb-2">{t('thankyouPopup.thankYouMessage1')}</h1>
                                        <h1 className="text-xl md:text-2xl font-semibold mb-4">{t('thankyouPopup.thankYouMessage2')}</h1>
                                    </div>
                                    <button className="europe-thankyou-subBtn px-8 py-2 text-white rounded-full text-sm" onClick={goToHomePage}>
                                        {t('thankyouPopup.getStartedButton')}
                                    </button>
                                    <p className="europe-thankyouQuestion mt-4 text-xs">
                                        <span className='font-bold'>{t('thankyouPopup.questions')}</span><span> {t('thankyouPopup.contactEmail')}</span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div> */}

                    <div className="flex items-center my-4">
                        <input
                            id="agree"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className="hidden"
                        />
                        <label htmlFor="agree" className="custom-checkbox-label flex items-center">
                            <div className={`europe-custom-checkbox flex flex-shrink-0 ${isChecked ? 'checked' : ''}`}></div>
                            <span className="ml-2 text-gray-700 europe-bazaarPolicy">
                                {t('form.checkboxContectFirst')}{" "}
                                <a
                                    href={`https://eu.expobazaar.com${urlFlagName === "en" ? "" : `/${urlFlagName}`}/pages/our-policies`}
                                    className="underline"
                                >
                                    <b>{t('form.privacyPolicyLink')}</b>
                                </a>

                                {" "}
                                {t('form.checkboxContectSecond')}{" "}
                                <a
                                    href={`https://eu.expobazaar.com${urlFlagName === "en" ? "" : `/${urlFlagName}`}/policies/terms-of-service`}
                                    className="underline"
                                >
                                    <b>{t('form.termsOfUseLink')}</b>
                                </a>

                                .
                            </span>
                        </label>
                    </div>
                    {/* {showThankyouPopup === true ? "" : <p className="text-red-600">{errorMessage}</p>} */}

                    <p className="text-red-600">{errorMessage}</p>

                    <div className="flex justify-center md:justify-end py-2">

                        {(submitLoader === true) ? (<button
                            type="button"
                            className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                        >
                            <svg
                                aria-hidden="true"
                                role="status"
                                className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#1C64F2"
                                />
                            </svg>
                            {t('form.pleaseWait')}
                        </button>) : (<button
                            className={`${errorMessage !== "" || isChecked === false || vatValid != true || companyFound === true || buttonDisable === true ? "bg-white rounded-lg border-2 border-slate-200" : "europe-subBtn text-white"}  py-2 px-12`}

                            disabled={errorMessage !== "" || isChecked === false || vatValid != true || companyFound === true || buttonDisable === true}>
                            {t('form.next')}
                        </button>)}
                    </div>
                </div>
            </form>
            <div className="europe-container mx-auto px-4 py-6 relative">
                {showThankyouPopup && (
                    <>
                        <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-96 pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center">
                            <button className="text-3xl md:text-4xl absolute right-4 top-4" onClick={e => handleCrossClicked(e)}>&times;</button>
                            <img src={europeLogo} alt="Expo Bazaar Logo" className="mx-auto mb-4 w-32 md:w-40" />
                            <div className='lastPageContainer pt-4 pb-4 md:pt-5 md:pb-5'>
                                <h1 className="text-xl md:text-2xl font-semibold mb-2">{t('thankyouPopup.thankYouMessage1')}</h1>
                                <h1 className="text-xl md:text-2xl font-semibold mb-4">{t('thankyouPopup.thankYouMessage2')}</h1>
                            </div>
                            <button className="europe-thankyou-subBtn px-8 py-2 text-white rounded-full text-sm" onClick={goToHomePage}>
                                {t('thankyouPopup.getStartedButton')}
                            </button>
                            <p className="europe-thankyouQuestion mt-4 text-xs">
                                <span className='font-bold'>{t('thankyouPopup.questions')}</span><span> {t('thankyouPopup.contactEmail')}</span>
                            </p>
                        </div>
                    </>
                )}
            </div>

        </div >
    );
}

export default EuropeRegistrationForm;